<template>
    <div>
        <el-select
            :disabled="disabled"
            :multiple="multiple"
            v-model="select_value"
            filterable
            remote
            :placeholder="placeholder"
            :remote-method="remoteMethod"
            :loading="loading"
            @change="handleChange"
            clearable
        >
            <el-option v-for="item in selectPageOptions" :key="item[show_id]" :label="item[show_field]" :value="item[show_id]"> </el-option>
           <div style="padding-bottom: 40px;"></div>
            <div class="selectPage">
                <el-link class="mr10" :underline="false" :disabled="page === 1" @click="moreOriginPage('preview')">上一页</el-link>
                <el-link class="mr10" :underline="false" :disabled="!has_next" @click="moreOriginPage('next')">下一页</el-link>
            </div>
        </el-select>
    </div>
</template>

<script>
export default {
    name: 'SelectPage',
    props: {
        url: String,
        show_field: {
            type: String,
            default: 'name',
        },
        show_id: {
            type: String,
            default: 'id',
        },
        query_field: {
            type: String,
            default: 'name',
        },
        value: [Number, String, Array],
        disabled: Boolean,
        multiple: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '请输入',
        },
        filter: {
            type: Object,
            default: () => {},
        },
        // 分页数据回显使用
        otherData:{
            type: Object,
            default: () => {},
        }
    },
    data() {
        return {
            select_value: '',
            page: 1,
            limit: 10,
            keyword: '',
            has_next: true,
            loading: false,
            selectPageOptions: [],
        }
    },
    watch: {
        filter: {
            handler(val) {
                if (val) {
                    this.remoteMethod(this.keyword)
                }
            },
            immediate: true,
            deep: true,
        },

        value:{
            handler(newVal){
                if (this.multiple) {
                    console.log(newVal)
                   this.select_value = Array.isArray(newVal) ? newVal : [newVal]
                } else {
                    this.select_value = newVal
                }
                this.remoteMethod('',1,null,{sort_value:newVal})
            },
            immediate: true,
            deep: true,

        },
    },
    created() {

        // this.remoteMethod('')
    },
    destroyed() {},
    methods: {
        // 检查selectPage首页数据中是否有回显中的值
        checkSelectPageDataIsValuable(array){
           let newArray= array.find(item=>item[this.show_id]==this.value)
           return Boolean(newArray)
        },

        // 远程分页取值
        remoteMethod(keyword, step, query_value,sources) {
            this.keyword = keyword
            this.loading = true
            this.request
                .get(this.url, {
                    params: Object.assign({
                        page: this.page,
                        limit: this.limit,
                        show_id: this.show_id,
                        show_field: this.show_field,
                        sort_value:this.$attrs.sort_value?this.$attrs.sort_value:'',
                        query_field: query_value ? 'id' : this.query_field,
                        keyword: this.keyword,
                        ...(query_value ? { query_value } : {}), // 编辑的时候传
                        ...this.formatQueryParams(this.filter), // 其他检索条件
                    }, sources)
                })
                .then(response => {
                    this.loading = false
                    if (response.data.length > 0) {
                        this.has_next = true
                        if(this.url=='/admin/my.user/selectPage'||this.url=='/admin/user/selectPage'){
                            this.selectPageOptions = response.data.map(v=>{
                               return{
                                ...v,
                                nickname:  `(UID${v.uid})${v.nickname}`
                               }
                            })
                        }else{
                            this.selectPageOptions = response.data
                        }

                        // if(!this.checkSelectPageDataIsValuable(response.data)&&this.page===1){
                        //     this.selectPageOptions.push({id:this.value,name:this.otherData.name})
                        // }

                    } else if (step === 'next') {
                        this.has_next = false
                        this.page--
                    }
                })
        },

        // 上下页操作
        moreOriginPage(step) {
            if (step === 'preview') {
                this.page <= 0 ? (this.page = 1) : --this.page
                this.remoteMethod(this.keyword)
            } else {
                ++this.page
                this.remoteMethod(this.keyword, step)
            }
        },

        handleChange(val) {
            //返回 v-model的参数（关键）
            this.$emit('input', val)
        },
    },
}
</script>
